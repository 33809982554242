import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { bp, gv, ns } from '../../utils';
import { PropsWithChildren } from 'react';

const Article = styled.div`
	margin: 0;
	font-size: ${props => ns(props.theme.fz.base)};
	color: ${gv('textColor')};
	line-height: 1.5;
`;

const Heading = styled.h3`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.large2)};
	line-height: 1.5;
	font-weight: bold;
	color: ${gv('headingColor')};
	margin: 0 0 ${props => ns(props.theme.gutter * 1.5)} 0;
`;

const Image = styled.div`
	width: 100px;
	flex: 0 0 100px;
	@media ${props => bp(props, 'tablet')} {
		width: 200px;
		flex: 0 0 200px;
	}
`;

const Text = styled.div`
	flex: 0 0 100%;
	width: 100%;
	padding: ${props => ns(props.theme.gutter)} 0 0 0;
	${Article},
	${Heading} {
		text-align: center;
	}
	@media ${props => bp(props, 'tablet')} {
		flex: 0 0 calc(100% - 200px);
		width: calc(100% - 200px);
		padding: 0 0 0 ${props => ns(props.theme.gutter)};
		${Article},
		${Heading} {
			text-align: left;
		}
	}
`;

const Container = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	margin: ${props => ns(props.theme.gutter * 2)} auto;
	position: relative;

	&.centered {
		${Text} {
			max-width: 600px;
			${Article} {
				text-align: center;
			}
		}
		@media ${props => bp(props, 'desktop')} {
			max-width: 950px;
			padding-left: 120px;
			padding-right: 120px;
			${Image} {
				position: absolute;
				left: 60px;
				width: 100px;
				flex-basis: 100px;
			}
			${Text} {
				width: 100%;
				flex-basis: 100%;
			}
		}
	}

	&.boxed {
		max-width: 760px;
		padding: ${props => ns(props.theme.gutter)};
		background-color: ${gv('backgroundShade')};
		border-radius: ${props => ns(props.theme.borderRadiusBase)};
	}
`;

export type MoneyBackProps = {
	layout: 'centered' | 'boxed';
};
export default function MoneyBack(props: PropsWithChildren<MoneyBackProps>) {
	const { layout, children } = props;

	return (
		<Container className={layout}>
			<Image>
				<StaticImage
					src="../../images/mbg.png"
					alt="30 days money back guarantee"
					layout="constrained"
					width={200}
					placeholder="tracedSVG"
					tracedSVGOptions={{
						color: '#DE911D',
					}}
				/>
			</Image>
			<Text>{children}</Text>
		</Container>
	);
}

MoneyBack.Article = Article;
MoneyBack.Heading = Heading;
