import classNames from 'classnames';
import React, { ComponentType, createElement, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import pricingData from '../../../fs-api/pricing-data.json';
import {
	bp,
	controlSkewCss,
	controlSkewReverseCss,
	gridContainerCss,
	gv,
	ns,
} from '../../utils';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLifeRing } from '@fortawesome/free-solid-svg-icons/faLifeRing';
import $Popup from 'reactjs-popup';
import { trackPurchaseGa, useFsCheckout } from './checkout';
import PurchaseComplete from '../PurchaseComplete';
import { ReactComponent as BusinessPlanSvg } from './business-plan.svg';
import { ReactComponent as CloudOneSvg } from './cloud-one.svg';
import { ReactComponent as CloudTwoSvg } from './cloud-two.svg';
import { ReactComponent as FreePlanSvg } from './free-plan.svg';
import { ReactComponent as ProPlanSvg } from './pro-plan.svg';
import { ReactComponent as StarterPlanSvg } from './starter-plan.svg';
import { ReactComponent as WaveSvg } from './wave.svg';
import Cta from '../Cta';
import { ControlGroup, Select } from '../Controls';

const planSvgs: Record<string, ComponentType<any>> = {
	free: FreePlanSvg,
	starter: StarterPlanSvg,
	professional: ProPlanSvg,
	business: BusinessPlanSvg,
};

const Price = styled.div`
	margin: 0 0 ${(props) => ns(props.theme.gutter * 1.5)} 0;
`;
const PriceValue = styled.p`
	margin: ${(props) => ns(props.theme.gutter * 1.5)} 0
		${(props) => ns(props.theme.gutter)};
	font-size: ${(props) => ns(props.theme.fz.large7)};
	font-family: ${(props) => props.theme.fontFamilyLogo};
	font-weight: 800;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row wrap;
	line-height: 1;
	.dot,
	.decimal {
		font-size: ${(props) => ns(props.theme.fz.large3)};
	}
	.dollar {
		font-size: ${(props) => ns(props.theme.fz.large7 - 10)};
	}
	.dot,
	.decimal {
		align-self: flex-start;
		margin-top: 0.25em;
	}
	.separator {
		font-size: ${(props) => ns(props.theme.fz.large7 - 10)};
		margin: 0 4px;
		font-weight: normal;
	}
	.cycle {
		font-size: ${(props) => ns(props.theme.fz.large2)};
		font-family: ${(props) => props.theme.fontFamilyHeading};
		font-weight: normal;
		text-transform: uppercase;
		text-align: left;
		align-self: flex-end;
		margin-bottom: 0.25em;
	}
`;
const PriceNotApplicable = styled.p`
	margin: 0 0 ${(props) => ns(props.theme.gutter)} 0;
	padding: 0 ${(props) => ns(props.theme.gutter)};
	text-align: center;
	font-size: ${(props) => ns(props.theme.fz.large1)};
	color: ${gv('textColorSecondary')};
	span {
		position: relative;
		&::after {
			position: absolute;
			right: -10px;
			left: -10px;
			content: '';
			height: 2px;
			background-color: ${gv('textColorSecondary')};
			transform-origin: center center;
			top: 50%;
			transform: translateY(-50%) rotate(6deg);
		}
	}
	&.hidden {
		visibility: hidden;
	}
`;
const Discount = styled.p`
	margin: 0 auto;
	width: 100px;
	font-size: ${(props) => ns(props.theme.fz.small2)};
	text-align: center;
	line-height: 1;
	font-family: ${(props) => props.theme.fontFamilyHeading};
	font-weight: bold;
	background-color: ${gv('primaryColor')};
	color: ${gv('primaryBgText')};
	// prettier-ignore
	padding: ${(props) => ns(props.theme.gutter * 0.3)}	${(props) =>
		ns(props.theme.gutter * 0.5)};
	text-transform: uppercase;
	${controlSkewCss};
	box-shadow: 3px 3px 0 0 ${gv('controlShadowPrimary')};
	.num {
		font-family: ${(props) => props.theme.fontFamilyLogo};
	}
	span {
		${controlSkewReverseCss};
	}
	&.hidden {
		visibility: hidden;
	}
`;
const Billed = styled.p`
	margin: ${(props) => ns(props.theme.gutter / 2)} 0 0 0;
	font-size: ${(props) => ns(props.theme.fz.base)};
	text-align: center;
	line-height: 1;
	strong {
		font-family: ${(props) => props.theme.fontFamilyLogo};
	}
	padding: 0 ${(props) => ns(props.theme.gutter)};
	&.hidden {
		visibility: hidden;
	}
`;

const Controls = styled.div`
	padding: 0 ${(props) => ns(props.theme.gutter)};
`;

type BillingCycles = 'annual' | 'monthly' | 'lifetime';
function PriceBlock(props: {
	licenses: number;
	billingCycle: BillingCycles;
	currentPlan: typeof pricingData[0];
}) {
	const { billingCycle, licenses, currentPlan } = props;

	// if no pricing, then return $0
	if (!currentPlan.pricing.length) {
		return (
			<Price>
				<PriceNotApplicable
					className={classNames({ hidden: true })}
					aria-hidden
				>
					<span>$0.00 / mo</span>
				</PriceNotApplicable>
				<PriceValue>
					<span className="dollar">$</span>
					<span className="value">0</span>
					<span className="dot">.</span>
					<span className="decimal">00</span>
				</PriceValue>
				<Discount className={classNames({ hidden: true })} aria-hidden>
					<span>
						Save <span className="num">0%</span>
					</span>
				</Discount>
				<Billed className="hidden" aria-hidden>
					Billed <strong>$0.00</strong> USD
				</Billed>
			</Price>
		);
	}
	const currentPrice = currentPlan.pricing.find(
		(price) => price.licenses === licenses
	);
	if (!currentPrice) {
		return null;
	}
	const baseMonthlyPrice = (
		currentPlan.pricing.find((price) => price.licenses === 1) ??
		currentPlan.pricing[0]
	).monthly_price;
	let currentPriceToShow = currentPrice.monthly_price;
	let currentMonthlyPrice = currentPrice.monthly_price;
	let todaysBill = currentPrice.monthly_price;
	let cycleText = ' / month';
	if (billingCycle === 'annual') {
		currentMonthlyPrice = currentPrice.annual_price / 12;
		currentPriceToShow = currentMonthlyPrice;
		todaysBill = currentPrice.annual_price;
		cycleText = ' / year';
	} else if (billingCycle === 'lifetime') {
		currentMonthlyPrice = currentPrice.lifetime_price / (12 * 5);
		currentPriceToShow = currentPrice.lifetime_price;
		todaysBill = currentPrice.lifetime_price;
		cycleText = ' once';
	}

	const discount =
		baseMonthlyPrice === currentMonthlyPrice
			? '0.00'
			: (
					((baseMonthlyPrice - currentMonthlyPrice / licenses) /
						baseMonthlyPrice) *
					100
			  ).toFixed(0);
	const priceParts = currentPriceToShow.toFixed(2).split('.');
	return (
		<Price>
			<PriceNotApplicable
				className={classNames({ hidden: discount === '0.00' })}
			>
				<span>${(baseMonthlyPrice * licenses).toFixed(2)} / mo</span>
			</PriceNotApplicable>
			<PriceValue>
				<span className="dollar">$</span>
				<span className="value">{priceParts[0]}</span>
				<span className="dot">.</span>
				<span className="decimal">{priceParts[1]}</span>
				{billingCycle !== 'lifetime' ? (
					<>
						<span className="separator">|</span>
						<span className="cycle">
							per
							<br />
							month
						</span>
					</>
				) : null}
			</PriceValue>
			<Discount className={classNames({ hidden: discount === '0.00' })}>
				<span>
					Save <span className="num">{discount}%</span>
				</span>
			</Discount>
			<Billed>
				Billed <strong>${todaysBill.toFixed(2)}</strong> USD{cycleText}
			</Billed>
		</Price>
	);
}

const popupKf = keyframes`
	0% {
		transform: scale(1) translateY(0px);
		opacity: 0;
	}
	1% {
		transform: scale(0.96) translateY(10px);
		opacity: 0;
	}
	100% {
		transform: scale(1) translateY(0px);
		opacity: 1;
	}
`;

const Popup = styled($Popup)`
	// use your custom style for ".popup-overlay"
	&-overlay {
	}
	// use your custom style for ".popup-content"
	&-content {
		background-color: ${gv('textColor')};
		color: ${gv('appBackgroundColor')};
		padding: ${(props) => ns(props.theme.gutter / 2)};
		line-height: 1.5;
		max-width: 200px;
		animation: ${popupKf} 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
		border-radius: ${(props) => ns(props.theme.borderRadiusBase)};
		box-shadow: ${(props) => props.theme.boxShadow3dp};
	}
	&-arrow {
		color: ${gv('textColor')};
	}
`;

const FeatureList = styled.ul`
	margin: ${(props) => ns(props.theme.gutter * 2)} 0 0 0;
	list-style: none;
	padding: 0 ${(props) => ns(props.theme.gutter * 2)};
	&:last-child {
		padding-bottom: ${(props) => ns(props.theme.gutter * 2)};
	}
`;
const FeatureIcon = styled.span`
	height: 1em;
	width: 1em;
	flex: 0 0 1em;
	margin-right: 1em;
	color: ${gv('textColorSecondary')};
`;
const FeatureDescription = styled.span`
	flex: 1 0 auto;
	max-width: 100%;
	line-height: 1.5;
	> span {
		padding-bottom: 4px;
		border-bottom: 2px dashed ${gv('borderColorSplit')};
	}
`;
const FeatureItem = styled.li`
	margin: 0;
	padding: 0 0 ${(props) => ns(props.theme.gutter / 2)} 0;
	&:last-child {
		padding-bottom: 0;
	}
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
`;

const PlanName = styled.p`
	font-family: ${(props) => props.theme.fontFamilyHeading};
	font-size: ${(props) => ns(props.theme.fz.large3)};
	font-weight: bold;
	padding: ${(props) => ns(props.theme.gutter)} 0
		${(props) => ns(props.theme.gutter * 2)};
	text-align: center;
	color: ${gv('textColor')};
	text-transform: uppercase;
	margin: 0;
`;
const Title = styled.h2`
	font-family: ${(props) => props.theme.fontFamilyHeading};
	font-size: ${(props) => ns(props.theme.fz.large1)};
	text-align: center;
	padding: 0 ${(props) => ns(props.theme.gutter)};
	margin: 0;
`;
const TopWave = styled.div`
	width: 100%;
	position: relative;
	margin-top: -5px;
	svg {
		display: block;
		width: 100%;
		height: auto;
		fill: ${gv('backgroundSelected')};
	}
	pointer-events: none;
`;
const TopPlanIcon = styled.div`
	max-width: 256px;
	margin: 0 auto;
	padding: ${(props) => ns(props.theme.gutter * 2)} 0 0;
	svg {
		display: block;
		width: 100%;
		height: auto;
		fill: ${gv('textColorSecondary')};
	}
`;
const TopCloud = styled.div`
	position: absolute;
	pointer-events: none;
	height: 80px;
	width: 80px;
	top: 3%;
	left: 5%;
	&.two {
		top: auto;
		bottom: 40%;
		left: auto;
		right: 3%;
	}
	svg {
		opacity: 0.75;
		display: block;
		width: 100%;
		fill: ${gv('backgroundShade')};
	}
`;

const TopBg = styled.div`
	background-color: ${gv('backgroundSelected')};
`;
const Top = styled.div`
	position: relative;

	&.featured {
		${TopBg} {
			background-color: ${gv('secondaryAccentColor')};
		}
		${TopWave} {
			svg {
				fill: ${gv('secondaryAccentColor')};
			}
		}
		${TopPlanIcon} {
			svg {
				fill: rgba(255, 255, 255, 0.9);
			}
		}
		${TopCloud} {
			svg {
				fill: rgba(255, 255, 255, 0.2);
			}
		}
		${PlanName} {
			color: ${gv('primaryBgText')};
		}
	}
`;

const Wrapper = styled.div`
	border-radius: ${(props) => ns(props.theme.borderRadiusBase * 4)};
	background-color: ${gv('appBackgroundColor')};
	box-shadow: ${(props) => props.theme.boxShadow1dp};
	overflow: hidden;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	> * {
		flex: 0 0 auto;
		&:last-child {
			margin-top: auto !important;
		}
	}
`;
const Pricing = styled.div`
	padding: ${(props) => ns(props.theme.gutter / 4)};
	flex: 0 0 100%;
	align-self: stretch;
	max-width: 350px;

	&.featured {
		${Discount} {
			background-color: ${gv('secondaryAccentColor')};
			box-shadow: 3px 3px 0 0 ${gv('controlShadowAccentSecondary')};
		}
	}
`;

const PricingTable = styled.div`
	${gridContainerCss};
	margin-left: ${(props) => ns(props.theme.gutter / -4)};
	margin-right: ${(props) => ns(props.theme.gutter / -4)};
	align-items: flex-start;
	justify-content: center;

	@media ${(props) => bp(props, 'tablet')} {
		${Pricing} {
			flex-basis: 50%;
		}
	}
	@media ${(props) => bp(props, 'desktop')} {
		${Pricing} {
			flex-basis: 33.33333333%;
		}
	}
	@media ${(props) => bp(props, 'desktopHd')} {
		${Pricing} {
			flex-basis: 25%;
		}
	}
`;

const Container = styled.div``;

type FeatureType =
	| {
			title: string;
			description: string;
			is_featured: boolean;
			id: string;
	  }
	| {
			title: string;
			description: null;
			is_featured: boolean;
			id: string;
	  };
const planUniqueFeatures: { [planId: string]: FeatureType[] } = {};
const addedFeatures: string[] = [];
pricingData.forEach((plan, index) => {
	planUniqueFeatures[plan.id] = [];
	if (index !== 0) {
		planUniqueFeatures[plan.id].push({
			title: `All from ${pricingData[index - 1].title} plan`,
			description: null,
			is_featured: false,
			id: 'all',
		});
	}
	plan.features.forEach((feature) => {
		if (!addedFeatures.includes(feature.id)) {
			planUniqueFeatures[plan.id].push(feature);
		}
		addedFeatures.push(feature.id);
	});
});

export default function FullPricing() {
	const [licenses, setLicenses] = useState<number>(1);

	const [billingCycle, setBillingCycle] = useState<BillingCycles>('annual');

	const [purchaseData, setPurchaseData] = useState<{
		name: string;
		email: string;
		purchased: boolean;
	}>({ name: '', email: '', purchased: false });

	const fsCheckout = useFsCheckout();

	const success = (response: any) => {
		// set purchase data
		setPurchaseData({
			name: response?.user?.first ?? '',
			email: response?.user?.email ?? '',
			purchased: true,
		});
		// track with google analytics
		trackPurchaseGa(response);
	};

	return (
		<Container>
			{purchaseData.purchased ? (
				<PurchaseComplete
					onBlueBackground
					heading={`Congratulation on your purchase ${purchaseData.name}!`}
				>
					Please check your email <strong>{purchaseData.email}</strong> for
					download and installation instruction. If you have any issues, please
					see our <Link to="/docs/">documentation</Link> or open a new{' '}
					<Link to="/contact/">support ticket</Link>.
				</PurchaseComplete>
			) : null}
			<PricingTable>
				{pricingData.map((plan) => {
					const trialButton = (
						<Cta.Group
							size="small"
							align="justify"
							hasGutterHorizontal
							hasGutterVertical
						>
							<Cta
								type={plan.is_featured ? 'accent-secondary' : 'primary'}
								disabled={plan.is_hidden}
								to={() => {
									fsCheckout?.open({
										plan_id: plan.id,
										name: 'wpeform',
										licenses: licenses,
										billing_cycle: billingCycle,
										trial: 'paid',
										success,
									});
								}}
							>
								{plan.is_hidden ? 'Coming soon' : 'Start Trial'}
								<FontAwesomeIcon icon={faArrowRight} />
							</Cta>
						</Cta.Group>
					);
					const buyButton = (
						<Cta.Group
							size="small"
							align="justify"
							hasGutterHorizontal
							hasGutterVertical
						>
							<Cta
								disabled={plan.is_hidden}
								type={
									plan.is_featured
										? 'accent'
										: plan.pricing.length
										? 'default'
										: 'primary'
								}
								to={() => {
									fsCheckout?.open({
										plan_id: plan.id,
										name: 'wpeform',
										licenses: licenses,
										billing_cycle: billingCycle,
										success,
									});
								}}
							>
								{plan.is_hidden
									? 'Coming soon'
									: plan.pricing.length
									? 'Buy Now'
									: 'Get Started'}
								<FontAwesomeIcon icon={faArrowRight} />
							</Cta>
						</Cta.Group>
					);

					return (
						<Pricing
							key={plan.id}
							className={classNames({
								disabled: plan.is_hidden,
								featured: plan.is_featured,
							})}
						>
							<Wrapper>
								<Top
									className={classNames({
										disabled: plan.is_hidden,
										featured: plan.is_featured,
									})}
								>
									<TopBg>
										<TopCloud>
											<CloudOneSvg />
										</TopCloud>
										<TopCloud className="two">
											<CloudTwoSvg />
										</TopCloud>

										<TopPlanIcon>
											{planSvgs[plan.name] ? (
												createElement(planSvgs[plan.name])
											) : (
												<FreePlanSvg />
											)}
										</TopPlanIcon>
										<PlanName>{plan.title}</PlanName>
									</TopBg>
									<TopWave>
										<WaveSvg />
									</TopWave>
								</Top>
								<PriceBlock
									billingCycle={billingCycle}
									licenses={licenses}
									currentPlan={plan}
								/>
								<Title>{plan.description}</Title>
								{plan.pricing.length ? trialButton : buyButton}
								<Controls>
									<ControlGroup>
										<Select
											value={licenses.toString()}
											onChange={(e) => {
												setLicenses(Number.parseInt(e.target.value, 10));
											}}
										>
											<option value="1">Single Site</option>
											<option value="2">2 Sites</option>
											<option value="5">5 Sites</option>
											<option value="10">10 Sites</option>
										</Select>
										<Select
											value={billingCycle}
											onChange={(e) => {
												setBillingCycle(e.target.value as any);
											}}
										>
											<option value="monthly">Montly</option>
											<option value="annual">Yearly</option>
											<option value="lifetime">Lifetime</option>
										</Select>
									</ControlGroup>
								</Controls>
								<FeatureList>
									{planUniqueFeatures[plan.id].map((feature) => (
										<FeatureItem key={feature.id}>
											<FeatureIcon>
												<FontAwesomeIcon
													icon={feature.id === 'all' ? faPlus : faCheck}
												/>
											</FeatureIcon>
											<FeatureDescription
												className={classNames({
													hasTooltip: !!feature.description,
												})}
											>
												{feature.description ? (
													<Popup
														trigger={<span>{feature.title}</span>}
														position="top center"
														closeOnDocumentClick
														on={['hover', 'focus']}
													>
														<span>{feature.description}</span>
													</Popup>
												) : (
													feature.title
												)}
											</FeatureDescription>
										</FeatureItem>
									))}
									<FeatureItem key="support">
										<FeatureIcon>
											<FontAwesomeIcon icon={faLifeRing} />
										</FeatureIcon>
										<FeatureDescription>
											<strong>
												{plan.support.email
													? plan.name === 'starter'
														? 'Email support & kb'
														: 'Prirority email support & kb'
													: 'Community support & kb'}
												.
											</strong>
										</FeatureDescription>
									</FeatureItem>
								</FeatureList>
								{buyButton}
							</Wrapper>
						</Pricing>
					);
				})}
			</PricingTable>
		</Container>
	);
}
