import { Link, PageProps, graphql } from 'gatsby';
import React from 'react';
import Seo from '../components/Seo';
import { Constrain } from '../components/Containers';
import { GeneralBody } from '../components/Containers';
import GeneralHero from '../components/GeneralHero';
import PricingPageAnimation from '../components/Pricing/PageAnimation';
import Cta from '../components/Cta';
import FullPricing from '../components/Pricing/FullPricing';
import { Description, Divider, SectionHeading } from '../components/Typography';
import {
	Grid,
	Main,
	MainHeading,
	Sidebar,
	SidebarHeading,
} from '../components/SiteGrid';
import FAQsWidget from '../components/FAQs/Widget';
import TestimonialsWidget from '../components/Testimonials/Widget';
import PricingTable from '../components/Pricing/Table';
import MoneyBack from '../components/MoneyBack';
import SecurePricing from '../components/SecurePricing';
import FooterCredit from '../components/FooterCredit';

export default function PricingPage(
	props: PageProps<GatsbyTypes.PricingPageDataQuery>
) {
	const {
		data: { faqs, testimonials },
	} = props;
	return (
		<>
			<Seo
				title="WordPress Form Builder Plans and Pricing - WPEForm"
				description="Check plans and pricings of WPEForm, for small to large WordPress Form business owners."
			/>

			<Constrain className="wide">
				<GeneralBody id="compare-plans">
					<SectionHeading>
						Save thousands of dollars creating your next form
					</SectionHeading>
					<MoneyBack layout="centered">
						<MoneyBack.Article>
							WPEForm comes in 3 paid plans. We don't charge extra for add-ons.
							Choose the plan based on your use-case and start saving thousands
							of dollars creating the <strong>most beautiful forms</strong> on
							WordPress market.
						</MoneyBack.Article>
					</MoneyBack>
					<FullPricing />
					<SecurePricing />
					<MoneyBack layout="boxed">
						<MoneyBack.Heading>30 Days Money Back Guarantee</MoneyBack.Heading>
						<MoneyBack.Article>
							All our pricing are in <strong>USD</strong>. Every premium plan
							comes with a 30 Days Money Back Guarantee. Yearly plans come with
							perpetual fallback license. Please visit our{' '}
							<Link to="/licensing/">licensing page</Link> for more information.
						</MoneyBack.Article>
					</MoneyBack>
				</GeneralBody>
			</Constrain>
			<Constrain>
				<GeneralBody>
					<Grid>
						<Main>
							<MainHeading>Commonly asked questions</MainHeading>
							<FAQsWidget data={faqs} />
						</Main>
						<Sidebar>
							<SidebarHeading>Testimonials</SidebarHeading>
							<TestimonialsWidget narrow data={testimonials} />
						</Sidebar>
					</Grid>
				</GeneralBody>
			</Constrain>
			<GeneralHero
				left={<PricingPageAnimation />}
				right={
					<GeneralHero.Article>
						<GeneralHero.Title>Plans &amp; Pricing</GeneralHero.Title>
						<GeneralHero.Subtitle>
							Whether you're a small or large business owner or just getting
							started, we can help you!
						</GeneralHero.Subtitle>
						<Cta.Group size="regular" align="center-left-desktop">
							<Cta type="primary" to="#detailed-plans" size="regular">
								Compare plans
							</Cta>
							<Cta type="ghost" to="/examples/" size="regular">
								Examples
							</Cta>
						</Cta.Group>
					</GeneralHero.Article>
				}
			/>
			<Constrain>
				<GeneralBody id="detailed-plans">
					<SectionHeading as="p">Detailed Comparison</SectionHeading>
					<PricingTable />
					<Divider />
					<SectionHeading as="p">
						Need a custom plan or more bulk options?
					</SectionHeading>
					<Description className="centered">
						Just get in touch and we will figure something out!
					</Description>
					<Cta.Group align="center">
						<Cta to="/contact/">Contact Us</Cta>
					</Cta.Group>
				</GeneralBody>
			</Constrain>
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query PricingPageData {
		testimonials: allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/site-data/testimonials/**/*.mdx" }
			}
			sort: { order: ASC, fields: frontmatter___order }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						link
						star
						name
					}
					body
				}
			}
		}
		faqs: allMdx(
			filter: { fileAbsolutePath: { glob: "**/site-data/faqs/**/*.mdx" } }
			sort: { order: ASC, fields: frontmatter___order }
			limit: 10
		) {
			edges {
				node {
					id
					frontmatter {
						title
					}
					body
				}
			}
		}
	}
`;
