import React from 'react';
import styled from 'styled-components';
import { useIsDarkTheme } from '../../theme/Provider';
import { ns } from '../../utils';

const Container = styled.div`
	max-width: 250px;
	margin: ${props => ns(props.theme.gutter * 2)} auto;
	img {
		width: 100%;
		height: auto;
		display: block;
	}
`;

export default function SecurePricing() {
	const isDark = useIsDarkTheme();
	return (
		<Container>
			<img
				src={
					isDark
						? '//img.freemius.com/badges/freemius-badge-secure-payments-dark.svg'
						: '//img.freemius.com/badges/freemius-badge-secure-payments-light.svg'
				}
				width="250px"
				height="115px"
				loading="eager"
				alt="Secure payments by Freemius – Sell and market freemium and premium WordPress plugins &amp; themes"
			/>
		</Container>
	);
}
