import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import pricingData from '../../../fs-api/pricing-data.json';
import {
	buttonResetCss,
	getMultipleTransitionsWithWillChange,
	gv,
	ns,
} from '../../utils';
import PurchaseComplete from '../PurchaseComplete';
import { useFsCheckout, trackPurchaseGa } from './checkout';

const Table = styled.table`
	table-layout: fixed;
	border: 0 none;
	border-collapse: collapse;
	font-size: ${props => ns(props.theme.fz.small2)};
	text-align: left;
	th,
	td {
		// prettier-ignore
		padding: ${props => ns(props.theme.gutter)} ${props =>
			ns(props.theme.gutter / 2)};
	}

	thead,
	tfoot {
		th {
			background-color: ${gv('backgroundHover')};
			height: 40px;
			font-size: ${props => ns(props.theme.fz.small1)};
			color: ${gv('textColorSecondary')};
			padding-top: ${props => ns(props.theme.gutter / 2)};
			padding-bottom: ${props => ns(props.theme.gutter / 2)};
			font-family: ${props => props.theme.fontFamilyHeading};
			&:first-child {
				padding-left: ${props => ns(props.theme.gutter)};
			}
			text-transform: uppercase;
			text-align: center;
			&.feature {
				background-color: transparent;
				border: 0 none;
			}
		}
	}

	th {
		color: ${gv('headingColor')};
		font-weight: bold;
	}

	tbody {
		tr {
			transition: ${props =>
				getMultipleTransitionsWithWillChange(
					['background-color'],
					props.theme.transitionControl
				)};
			background-color: ${gv('backgroundShade')};
			th:first-child {
				padding-left: ${props => ns(props.theme.gutter)};
				color: ${gv('headingColor')};
				font-family: ${props => props.theme.fontFamilyHeading};
			}
		}
	}

	th,
	td {
		border: 1px solid ${gv('borderColorSplit')};
		min-width: 80px;
		text-align: center;
		&.feature {
			text-align: right;
			min-width: 200px;
			position: sticky;
			z-index: 10;
			left: 0;
			background-color: ${gv('backgroundShade')};

			.description {
				font-weight: normal;
				font-family: ${props => props.theme.fontFamilyBody};
				color: ${gv('textColorSecondary')};
			}
		}

		&.is-fa-icon {
			font-size: ${props => ns(props.theme.fz.large1)};
			color: ${gv('disabledColor')};
			&.active {
				color: ${gv('successColor')};
			}
		}

		&.pricing {
			text-align: right;
		}
		&.price {
			font-family: ${props => props.theme.fontFamilyMono};
			text-align: left;
			min-width: 110px;
			button {
				${buttonResetCss};
				color: ${gv('textColor')};
				padding-bottom: 2px;
				border-bottom: 2px dashed ${gv('borderColorBase')};
				&:hover,
				&:focus,
				&:active {
					border-color: ${gv('primaryLightColor')};
				}
				&:disabled {
					opacity: 0.5;
					cursor: not-allowed;
				}
			}
		}
	}
	tr.header {
		background-color: ${gv('backgroundSelected')};
		th.feature {
			background-color: ${gv('backgroundSelected')};
			text-align: right;
			&:not(.pricing) {
				border-right: 0 none;
				+ th {
					border-left: 0 none;
				}
			}
		}
	}
`;

const Container = styled.div`
	width: 100%;
	overflow: auto;
`;

const flattenedFeatures: typeof pricingData[0]['features'] = [];
const includedFeatures: string[] = [];
pricingData.forEach(plan => {
	plan.features.forEach(feature => {
		if (!includedFeatures.includes(feature.id)) {
			flattenedFeatures.push(feature);
			includedFeatures.push(feature.id);
		}
	});
});

const supportFeatures: {
	key: keyof typeof pricingData[0]['support'];
	label: string;
}[] = [
	{
		key: 'docs',
		label: 'Documentation',
	},
	{
		key: 'forum',
		label: 'Community Forum',
	},
	{
		key: 'email',
		label: 'Email Support',
	},
];

export default function PricingTable() {
	const [purchaseData, setPurchaseData] = useState<{
		name: string;
		email: string;
		purchased: boolean;
	}>({ name: '', email: '', purchased: false });

	const success = (response: any) => {
		// set purchase data
		setPurchaseData({
			name: response?.user?.first ?? '',
			email: response?.user?.email ?? '',
			purchased: true,
		});
		// track with google analytics
		trackPurchaseGa(response);
	};

	const handler = useFsCheckout();

	return (
		<Container>
			{purchaseData.purchased ? (
				<PurchaseComplete
					onBlueBackground
					heading={`Congratulation on your purchase ${purchaseData.name}!`}
				>
					Please check your email <strong>{purchaseData.email}</strong> for
					download and installation instruction. If you have any issues, please
					see our <Link to="/docs/">documentation</Link> or open a new{' '}
					<Link to="/contact/">support ticket</Link>.
				</PurchaseComplete>
			) : null}
			<Table>
				<thead>
					<tr>
						<th className="feature" />
						{pricingData.map(plan => (
							<th key={plan.id} className={plan.name} colSpan={3}>
								{plan.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr className="header">
						<th className="feature pricing">Pricing Breakup</th>
						{pricingData.map(plan => (
							<th key={plan.id} className={plan.name} colSpan={3}>
								{plan.is_hidden ? 'Coming soon...' : ''}
							</th>
						))}
					</tr>
					{[1, 2, 5, 10].map(licenses => (
						<React.Fragment key={licenses}>
							<tr key={licenses} className="licenses">
								<th className="feature" rowSpan={3}>
									{licenses} Site{licenses !== 1 ? 's' : ''}
								</th>
								{/** Monthly */}
								{pricingData.map(plan => {
									const currentPricing =
										plan.pricing.find(price => price.licenses === licenses)
											?.monthly_price ?? 0;
									return (
										<React.Fragment key={plan.id}>
											<th
												className={classNames('pricing', 'monthly')}
												colSpan={2}
											>
												Monthly
											</th>
											<td className={classNames('price', 'monthly')}>
												<button
													onClick={e => {
														e.preventDefault();
														handler.open({
															plan_id: plan.id,
															name: 'wpeform',
															licenses: licenses,
															billing_cycle: 'monthly',
															success,
														});
													}}
													disabled={plan.is_hidden}
												>
													${currentPricing.toFixed(2)} USD
												</button>
											</td>
										</React.Fragment>
									);
								})}
							</tr>
							<tr>
								{/** Annually */}
								{pricingData.map(plan => {
									const currentPricing =
										plan.pricing.find(price => price.licenses === licenses)
											?.annual_price ?? 0;
									return (
										<React.Fragment key={plan.id}>
											<th
												className={classNames('pricing', 'annual')}
												colSpan={2}
											>
												Yearly
											</th>
											<td className={classNames('price', 'annual')}>
												<button
													onClick={e => {
														e.preventDefault();
														handler.open({
															plan_id: plan.id,
															name: 'wpeform',
															licenses: licenses,
															billing_cycle: 'annual',
															success,
														});
													}}
													disabled={plan.is_hidden}
												>
													${currentPricing.toFixed(2)} USD
												</button>
											</td>
										</React.Fragment>
									);
								})}
							</tr>
							<tr>
								{/** Lifetime */}
								{pricingData.map(plan => {
									const currentPricing =
										plan.pricing.find(price => price.licenses === licenses)
											?.lifetime_price ?? 0;
									return (
										<React.Fragment key={plan.id}>
											<th
												className={classNames('pricing', 'lifetime')}
												colSpan={2}
											>
												Lifetime
											</th>
											<td className={classNames('price', 'lifetime')}>
												<button
													onClick={e => {
														e.preventDefault();
														handler.open({
															plan_id: plan.id,
															name: 'wpeform',
															licenses: licenses,
															billing_cycle: 'lifetime',
															success,
														});
													}}
													disabled={plan.is_hidden}
												>
													${currentPricing.toFixed(2)} USD
												</button>
											</td>
										</React.Fragment>
									);
								})}
							</tr>
						</React.Fragment>
					))}
					<tr className="header">
						<th className="feature">Features</th>
						<th colSpan={pricingData.length * 3} />
					</tr>
					{flattenedFeatures.map(feature => (
						<tr key={feature.id}>
							<th className="feature">
								<span>{feature.title}</span>
								{feature.description ? (
									<p className="description">{feature.description}</p>
								) : null}
							</th>
							{pricingData.map(plan => {
								const hasFeature = plan.features.some(
									ft => ft.id === feature.id
								);

								return (
									<th
										key={plan.id}
										colSpan={3}
										className={classNames(plan.name, 'is-fa-icon', {
											active: hasFeature,
										})}
									>
										<FontAwesomeIcon icon={hasFeature ? faCheck : faTimes} />
									</th>
								);
							})}
						</tr>
					))}
					<tr className="header">
						<th className="feature">Product support</th>
						<th colSpan={pricingData.length * 3} />
					</tr>
					{supportFeatures.map(sf => (
						<tr key={sf.key}>
							<th className="feature">{sf.label}</th>
							{pricingData.map(plan => {
								const hasFeature = !!plan.support[sf.key];
								return (
									<th
										key={plan.id}
										colSpan={3}
										className={classNames(plan.name, 'is-fa-icon', {
											active: hasFeature,
										})}
									>
										<FontAwesomeIcon icon={hasFeature ? faCheck : faTimes} />
									</th>
								);
							})}
						</tr>
					))}
					<tr>
						<th className="feature">Priority email support</th>
						{pricingData.map(plan => {
							const hasFeature = ['professional', 'business'].includes(
								plan.name
							);
							return (
								<th
									key={plan.id}
									colSpan={3}
									className={classNames(plan.name, 'is-fa-icon', {
										active: hasFeature,
									})}
								>
									<FontAwesomeIcon icon={hasFeature ? faCheck : faTimes} />
								</th>
							);
						})}
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th className="feature" />
						{pricingData.map(plan => (
							<th key={plan.id} className={plan.name} colSpan={3}>
								{plan.title}
							</th>
						))}
					</tr>
				</tfoot>
			</Table>
		</Container>
	);
}
