import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { ns } from '../../utils';
const LazyAnimation = lazy(() => import('./LazyAnimation'));

const Placeholder = styled.div`
	height: 0;
	width: 100%;
	padding-bottom: 56.25%;
`;

const Animation = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
`;

const Container = styled.div`
	max-width: 600px;
	margin: 0 auto;
	padding: ${props => ns(props.theme.gutter)};
	position: relative;
`;

export default function PricingPageAnimation() {
	const isSSR = typeof window === 'undefined';
	return (
		<Container>
			<Placeholder />
			<Animation>
				{!isSSR ? (
					<Suspense fallback={<div />}>
						<LazyAnimation />
					</Suspense>
				) : null}
			</Animation>
		</Container>
	);
}
